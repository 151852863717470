"use strict";

onloadDo = () ->
  # Canvas settings
  canvas = document.getElementById 'canvas'
  paper.setup canvas
  
  # Getting the view
  view = paper.project.view

  # The fun part
  paths = new paper.Group
  
  # Adding points to path
  addPoints = (path, quantity) ->
    # Opening point
    path.add view.bounds.bottomLeft
    
    # Middle points
    for i in [-1..quantity+1] by 1
      x = view.viewSize.width/quantity * i
      y = view.viewSize.height / 1.618
      path.add new paper.Point x, y
      
    # Closing point
    path.add view.bounds.bottomRight
    
  # Adding paths
  addPath = (quantity, color, opacity) ->
    path = new paper.Path()
    path.fillColor = color
    path.opacity = opacity
    
    addPoints path, quantity
    path.smooth()
    
    path
    
  # Animating the paths
  animatePath = (path, event, index) ->
    for segment, i in path.segments
      if i > 0 and i < path.segments.length - 1
        sin = Math.sin event.time * 3 + i - index
        segment.point.y = sin * 15 + view.viewSize.height / 1.618 +  index * 15
    
  # Creating the paths
  n = 8
  opacity = 1 / (n/2)
  for i in [1..n] by 1
    path = addPath 8-i, '#21f8f6', i * opacity
    path.position.y += 25 * i
    paths.addChild path
    
  # View events
  view.onFrame = (event) ->
    for path, i in paths.children
      animatePath path, event, i
    
  # Logs
  console.log '###'
  
  # Draw the results
  view.draw()
    
  null

  
# ----- onload do -----
window.onload = onloadDo